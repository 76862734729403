* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none;
}
html,
body {
  height: 100%;
}
body {
  font-size: 1.5rem;
  -webkit-font-smoothing: subpixel-antialiased;
}
ul {
  list-style-type: none;
}
table {
  border: none !important;
  border-collapse: collapse;
}
table th,
table tr,
table td {
  border: none;
  padding: 0;
}
table tr {
  border-bottom: 1px solid $silver;
}
h1,
h2,
h3,
h4 {
  margin: 0;
  line-height: 1.2em;
  color: $baseColour;
}
h1 {
  font-size: 4.7rem;
}
h2 {
  font-size: 2.8rem;
}
img {
  display: block;
}
.hidden {
  display: none;
}
@-moz-keyframes hr {
  100% {
    -webkit-transform: rotate(720deg);
    -moz-transform: rotate(720deg);
    -ms-transform: rotate(720deg);
    -o-transform: rotate(720deg);
    -webkit-transform: rotate(720deg);
    -moz-transform: rotate(720deg);
    -ms-transform: rotate(720deg);
    -o-transform: rotate(720deg);
    transform: rotate(720deg);
  }
}
@-webkit-keyframes hr {
  100% {
    -webkit-transform: rotate(720deg);
    -moz-transform: rotate(720deg);
    -ms-transform: rotate(720deg);
    -o-transform: rotate(720deg);
    -webkit-transform: rotate(720deg);
    -moz-transform: rotate(720deg);
    -ms-transform: rotate(720deg);
    -o-transform: rotate(720deg);
    transform: rotate(720deg);
  }
}
@-o-keyframes hr {
  100% {
    -webkit-transform: rotate(720deg);
    -moz-transform: rotate(720deg);
    -ms-transform: rotate(720deg);
    -o-transform: rotate(720deg);
    -webkit-transform: rotate(720deg);
    -moz-transform: rotate(720deg);
    -ms-transform: rotate(720deg);
    -o-transform: rotate(720deg);
    transform: rotate(720deg);
  }
}
@keyframes hr {
  100% {
    -webkit-transform: rotate(720deg);
    -moz-transform: rotate(720deg);
    -ms-transform: rotate(720deg);
    -o-transform: rotate(720deg);
    -webkit-transform: rotate(720deg);
    -moz-transform: rotate(720deg);
    -ms-transform: rotate(720deg);
    -o-transform: rotate(720deg);
    transform: rotate(720deg);
  }
}
@-moz-keyframes min {
  100% {
    -webkit-transform: rotate(720deg);
    -moz-transform: rotate(720deg);
    -ms-transform: rotate(720deg);
    -o-transform: rotate(720deg);
    -webkit-transform: rotate(720deg);
    -moz-transform: rotate(720deg);
    -ms-transform: rotate(720deg);
    -o-transform: rotate(720deg);
    transform: rotate(720deg);
  }
}
@-webkit-keyframes min {
  100% {
    -webkit-transform: rotate(720deg);
    -moz-transform: rotate(720deg);
    -ms-transform: rotate(720deg);
    -o-transform: rotate(720deg);
    -webkit-transform: rotate(720deg);
    -moz-transform: rotate(720deg);
    -ms-transform: rotate(720deg);
    -o-transform: rotate(720deg);
    transform: rotate(720deg);
  }
}
@-o-keyframes min {
  100% {
    -webkit-transform: rotate(720deg);
    -moz-transform: rotate(720deg);
    -ms-transform: rotate(720deg);
    -o-transform: rotate(720deg);
    -webkit-transform: rotate(720deg);
    -moz-transform: rotate(720deg);
    -ms-transform: rotate(720deg);
    -o-transform: rotate(720deg);
    transform: rotate(720deg);
  }
}
@keyframes min {
  100% {
    -webkit-transform: rotate(720deg);
    -moz-transform: rotate(720deg);
    -ms-transform: rotate(720deg);
    -o-transform: rotate(720deg);
    -webkit-transform: rotate(720deg);
    -moz-transform: rotate(720deg);
    -ms-transform: rotate(720deg);
    -o-transform: rotate(720deg);
    transform: rotate(720deg);
  }
}
@-moz-keyframes loaderFade {
  0% {
    zoom: 1;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    filter: alpha(opacity=0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    -ms-opacity: 0;
    -o-opacity: 0;
    opacity: 0;
  }
  100% {
    zoom: 1;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    filter: alpha(opacity=100);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    -ms-opacity: 1;
    -o-opacity: 1;
    opacity: 1;
  }
}
@-webkit-keyframes loaderFade {
  0% {
    zoom: 1;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    filter: alpha(opacity=0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    -ms-opacity: 0;
    -o-opacity: 0;
    opacity: 0;
  }
  100% {
    zoom: 1;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    filter: alpha(opacity=100);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    -ms-opacity: 1;
    -o-opacity: 1;
    opacity: 1;
  }
}
@-o-keyframes loaderFade {
  0% {
    zoom: 1;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    filter: alpha(opacity=0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    -ms-opacity: 0;
    -o-opacity: 0;
    opacity: 0;
  }
  100% {
    zoom: 1;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    filter: alpha(opacity=100);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    -ms-opacity: 1;
    -o-opacity: 1;
    opacity: 1;
  }
}
@keyframes loaderFade {
  0% {
    zoom: 1;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    filter: alpha(opacity=0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    -ms-opacity: 0;
    -o-opacity: 0;
    opacity: 0;
  }
  100% {
    zoom: 1;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    filter: alpha(opacity=100);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    -ms-opacity: 1;
    -o-opacity: 1;
    opacity: 1;
  }
}
html,
body {
  background-color: #eaeaea;
  height: unset;
}
#body {
  font-family: 'Helvetica', 'Arial', sans-serif;
  position: relative;
  font-size: 1rem;
  line-height: 1.3;
  letter-spacing: 0.02em;
}
#body h1,
#body h2,
#body h3,
#body h4,
#body h5 {
  padding: 0 0 0.16em;
  margin: 0 0 0.4em;
  border-bottom: 2px solid #222;
  font-weight: 300;
  line-height: 1.1;
}
#body h2 {
  font-size: 2.1rem;
}
#body a {
  display: inline-flex;
  text-decoration: none;
  color: inherit;
}
#body p {
  margin-bottom: 0.8em;
}
#body p a {
  border-bottom: 1px solid #222;
}
#body p:last-child {
  margin-bottom: 0;
}
#body .error {
  font-size: 0.8em;
  color: #f00;
}
#body .info {
  font-size: 0.8em;
  color: #2ecc71;
}
.swiper-container,
.swiper-wrapper {
  z-index: initial !important;
}
.swiper-container {
  width: 100%;
}
.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
}
.swiper-button-prev:after,
.swiper-button-next:after {
  display: none;
}
